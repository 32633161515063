/* OpenSans-400*/
@font-face {
	font-family: "OpenSans";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}
/* OpenSans-600*/
@font-face {
	font-family: "OpenSans";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("../fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
}