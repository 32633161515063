.root {
	max-width: 100%;
	width: 100%;
	height: 100%;
	margin: auto;
	padding: 16px;

	@media (min-width: 920px) {
		padding: 40px;
		max-width: 1800px;
	}
	@media (min-width: 1920px) {
		max-width: 1920px;
		padding: 100px;
	}
}

.noPadding {
	padding: 0;
}

.noPaddingTop {
	padding-top: 0;
}

.noPaddingBottom {
	padding-bottom: 0;
}
