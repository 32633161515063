.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 40px 0;
	margin: 0 auto;
	gap: 24px;
	max-width: 1340px;

	@media (min-width: 1280px) {
		padding: 80px 0;
		gap: 56px;
	}
}

.textWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	@media (min-width: 1280px) {
		gap: 16px;

		p {
			text-align: center;
		}
	}
}

.image {
	width: 100%;
	object-fit: contain;
}
