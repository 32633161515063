@import "./fonts.css";

html,
body {
	padding: 0;
	margin: 0;
}

body {
	font-family: OpenSans, sans-serif;
	font-style: normal;
	font-weight: 400;
	color: #363e40;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

p,
ol,
ul {
	font-size: 14px;
	line-height: 20px;
	margin: 0;

	@media (min-width: 1280px) {
		font-size: 16px;
		line-height: 150%;
	}
}

h1,
h2,
h3,
h4 {
	font-weight: 600;
}

h1 {
	margin: 0;
	font-size: 28px;
	line-height: 40px;
	letter-spacing: 0.28px;
	text-transform: uppercase;

	@media (min-width: 1280px) {
		font-size: 44px;
		line-height: 60px;
		letter-spacing: 0.44px;
	}
}

h2 {
	margin: 0;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.24px;
	text-transform: uppercase;

	@media (min-width: 1280px) {
		font-size: 40px;
		line-height: 56px;
		letter-spacing: 0.4px;
	}
}

h3 {
	margin: 0;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.2px;
	text-transform: uppercase;

	@media (min-width: 1280px) {
		font-size: 32px;
		line-height: 48px;
		letter-spacing: 0.32px;
	}
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

button {
	background-color: inherit;
	padding: 0;
	border: none;
}

.App {
	min-width: 320px;
}
