.wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 40px 0;

	@media (min-width: 1280px) {
		gap: 56px;
		padding: 80px 0;
	}
}

.list {
	display: flex;
	flex-direction: column;
	gap: 40px;

	@media (min-width: 1024px) {
		padding-left: 32px;
		border-left: 1px solid #215469;
	}
}

.image {
	object-fit: contain;
	width: 100%;
}
