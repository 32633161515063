.wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;

	padding-top: 24px;
	margin-top: 20px;
	padding-bottom: 40px;
	border-top: 1px solid #59595b;

	@media (min-width: 1280px) {
		align-items: center;
		text-align: center;
	}
}

.policyBlock {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.copyright {
		color: #121314;
	}

	.link {
		color: #00c9bf;
	}

	@media (min-width: 1280px) {
		flex-direction: row;
		align-items: center;
		gap: 56px;
	}
}

.info {
	color: rgba(89, 89, 91, 0.52);
}
