html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
}

body {
	margin: 0;
}

*,
*:hover,
*:focus,
*:active {
	outline: none !important;
}

a {
	color: initial;
	text-decoration: none;
	-webkit-text-decoration-skip: objects;
	background: transparent;
}

a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden],
template {
	display: none !important;
}

b,
strong {
	font-weight: 500;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

mark {
	color: #fff;
}

small {
	font-size: 80%;
}

sub {
	position: relative;
	bottom: -0.25em;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sup {
	position: relative;
	top: -0.5em;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

img {
	vertical-align: middle;
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 1em 4rem;
}

hr {
	box-sizing: content-box;
	height: 0;
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 0.1rem solid #eee;
}

textarea {
	overflow: auto;
	resize: none;
}

pre,
textarea {
	overflow: auto;
}

code,
kbd,
pre,
samp {
	font-size: 1em;
	font-family: monospace, monospace;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	color: inherit;
	font: inherit;
	line-height: inherit;
}

optgroup {
	font-weight: 500;
}

button {
	overflow: visible;
	text-transform: none;
}

select {
	text-transform: none;
}

button,
html input[type="button"] {
	cursor: pointer;
	-webkit-appearance: button;
}

input[type="reset"],
input[type="submit"] {
	cursor: pointer;
	-webkit-appearance: button;
}

[role="button"] {
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

input {
	line-height: normal;
	background-color: transparent;
	border-radius: 0;
	box-shadow: none;
}

input::-moz-focus-inner {
	padding: 0;
	border: 0;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="search"] {
	box-sizing: content-box;
}

input::-webkit-search-cancel-button,
input::-webkit-search-decoration {
	webkit-appearance: none;
}

fieldset {
	margin: 0 0.2rem;
	padding: 0.35em 0.625em 0.75em;
	border: 0.1rem solid #c0c0c0;
}

legend {
	padding: 0;
	border: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}

input::-webkit-input-placeholder {
	color: #535358;
}

input:-moz-placeholder {
	color: #535358;
}

input::-moz-placeholder {
	color: #535358;
}

input:-ms-input-placeholder {
	color: #535358;
}

input:focus::-webkit-input-placeholder {
	opacity: 0;
}

input:focus:-moz-placeholder {
	opacity: 0;
}

input:focus::-moz-placeholder {
	opacity: 0;
}

input:focus:-ms-input-placeholder {
	opacity: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	margin: 0;
	-webkit-appearance: none;
}
