.list {
	display: flex;
	gap: 24px;
	overflow: auto;
	width: 100%;

	.item {
		white-space: nowrap;
		color: white;
		transition: color linear 0.2s;
		cursor: pointer;

		@media (min-width: 1024px) {
			&:hover {
				color: #b5b5b5;
				text-decoration: underline;
			}
		}
	}
}
